<template>
    <div class="container">
        <top-layer></top-layer>
        <div class="content">
            <center-layer></center-layer>
            <div class="main" v-if="false">
                <div class="main-title">响应快&nbsp;价格低&nbsp;更安全</div>
                <div class="main-input">
                    <el-input
                        placeholder="请输入手机号码"
                        prefix-icon="el-icon-search"
                        v-model="phone">
                    </el-input>
                </div>
                <div class="main-input">
                    <el-input
                        placeholder="请输入验证码"
                        prefix-icon="el-icon-search"
                        v-model="code">
                    </el-input>
                    <div class="main-code">获取验证码</div>
                </div>
                <div class="main-join" @click="otherPage">立即加入</div>
                <div class="main-check">
                    <el-checkbox v-model="checked">已阅读并同意《第三方合作清单》《用户服务协议》《隐私政策》《用户授权协议》</el-checkbox>
                </div>
            </div>
            <div class="main" style="justify-content:center;">
                <div class="main-title" style="text-align:center">响应快&nbsp;价格低&nbsp;更安全</div>
                <div style="padding:0 50px 0 50px;margin-top:20px;">
                    <img src="../image/shipper-mp-code.jpg" style="width:100%;height:auto">
                </div>
            </div>
            <router-view></router-view>
        </div>
        <bottom-layer></bottom-layer>
    </div>
</template>
<script>
import topLayer from '../components/top.vue'
import bottomLayer from '../components/bottom.vue'
import centerLayer from '../components/center.vue'
export default {
    data() {
        return {
            phone: '',
            code: '',
            checked: true,
            show: false
        }
    },
    components: {
        topLayer,
        bottomLayer,
        centerLayer
    },
    watch: {
        "$route.path": {
            immediate: true,
            handler(val) {
                if(val == '/shipper') {
                    this.show = true
                }else {
                    this.show = false
                }
            }
      },
    },
    methods: {
        otherPage() {
            this.$router.push('/shipper/join')
        }
    }
}
</script>
<style lang="css" scoped>
.container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.content {
    width: 100%;
    height: calc(100vh - 250px);
    background: url(../image/banner.jpg) no-repeat;
    background-size: 100% 100%;
    position: relative;
    min-height: 700px;
}
.main {
    position: absolute;
    top: 150px;
    right: 15%;
    background: white;
    height: 400px;
    width: 400px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 30px 30px;
}
/* .main div {
    height: 50px;
} */
.main .main-title {
    height: 50px;
    font-size: 24px;
    font-weight: bold;
    text-align: left;
    line-height: 50px;
}
.main .main-input {
    height: 50px;
    /* display: flex; */
    position: relative;
}
.main .main-code {
    height: 100%;
    width: 150px;
    color: rgba(3, 93, 252, 1);
    position: absolute;
    right: 0;
    top: 0;
    line-height: 50px;
}
.main .main-join {
    height: 50px;
    width: 100%;
    background: rgba(3, 93, 252, 1);
    line-height: 50px;
    border-radius: 25px;
    color: white;
}
.main .main-check {
    text-align: left;
    height: 50px;
}

</style>
<style>
.el-input .el-input__inner {
    border-radius: 25px !important;
    background: rgb(246, 247, 249);
    height: 50px;
    font-size: 16px;
    font-weight: bold;
}
.el-checkbox__input {
    vertical-align: top;
}
.el-checkbox__label {
    white-space: normal;
}
.el-checkbox__inner {
    border-radius: 50%;
}
</style>
